import LightpadSvg from './Lightpad.svg?react';
import NeoSvg from './Neo.svg?react';

import Button from '../Button';

interface DeviceTypeSelectionProps {
  deviceType: DeviceTypes;
  setDeviceType: (newType: DeviceTypes) => void;
}

export enum DeviceTypes {
  LIGHTPAD = 'lightpad',
  TWEAK_NEO = 'tweak/neo',
}

const renderIcon = (type: DeviceTypes) => {
  if (type === DeviceTypes.LIGHTPAD) {
    return <LightpadSvg className="w-24" />;
  }
  return <NeoSvg className="w-24" />;
};

export const LS_DEVICE_TYPE = 'flashmylight-device-type';

function DeviceTypeSelection({
  deviceType,
  setDeviceType,
}: DeviceTypeSelectionProps) {
  return (
    <div className="grid grid-cols-2 gap-2 justify-around lg:flex">
      <Button
        id={DeviceTypes.LIGHTPAD}
        dataTestId={DeviceTypes.LIGHTPAD}
        className={`flex flex-col items-center justify-center col-span-1 shadow-lg rounded-xl ring-1 ring-black/5 shadow-black/10 p-2 lg:w-40 bg-indigo-100 ${
          deviceType === DeviceTypes.LIGHTPAD
            ? 'bg-opacity-50'
            : 'text-gray-400 bg-opacity-25'
        }`}
        onClick={() => {
          localStorage.setItem(LS_DEVICE_TYPE, DeviceTypes.LIGHTPAD.toString());
          setDeviceType(DeviceTypes.LIGHTPAD);
        }}
      >
        <div
          className={`flex items-center justify-center rounded-full p-2 ${
            deviceType === DeviceTypes.LIGHTPAD ? 'bg-white' : ''
          }`}
        >
          {renderIcon(DeviceTypes.LIGHTPAD)}
        </div>
        <span>Lightpad</span>
      </Button>
      <Button
        id={DeviceTypes.TWEAK_NEO}
        dataTestId={DeviceTypes.TWEAK_NEO}
        className={`flex flex-col items-center justify-center col-span-1 shadow-lg rounded-xl ring-1 ring-black/5 shadow-black/10 p-2 lg:w-40 bg-indigo-100 ${
          deviceType === DeviceTypes.TWEAK_NEO
            ? 'bg-opacity-50'
            : 'text-gray-400 bg-opacity-25'
        }`}
        onClick={() => {
          localStorage.setItem(
            LS_DEVICE_TYPE,
            DeviceTypes.TWEAK_NEO.toString(),
          );
          setDeviceType(DeviceTypes.TWEAK_NEO);
        }}
      >
        <div
          className={`flex items-center justify-center rounded-full p-2 ${
            deviceType === DeviceTypes.TWEAK_NEO ? 'bg-white' : ''
          }`}
        >
          {renderIcon(DeviceTypes.TWEAK_NEO)}
        </div>
        <span>Neo</span>
      </Button>
    </div>
  );
}
export default DeviceTypeSelection;
