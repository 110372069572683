import { useState } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { HiX } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';
import Card from '../Card';

const LS_HIDE_PHOTOSENSITIVE_WARNING = 'hide-photosensitive-warning';

function Warnings() {
  const [hidePhotosensitive, setHidePhotosensitive] = useState(
    localStorage.getItem(LS_HIDE_PHOTOSENSITIVE_WARNING)
      ? localStorage.getItem(LS_HIDE_PHOTOSENSITIVE_WARNING) === 'true'
      : false,
  );

  return !hidePhotosensitive ? (
    <Card className="text-sm text-red-600">
      <FaExclamationTriangle className="h-4 w-4" />
      <div className="grow justify-center">
        <b>
          <FormattedMessage id="Epilepsy warning" />:
        </b>{' '}
        <FormattedMessage id="Rapid flashes of light" />
      </div>
      <Button
        id="close-photosensitive-warning"
        onClick={() => {
          localStorage.setItem(
            LS_HIDE_PHOTOSENSITIVE_WARNING,
            JSON.stringify(true),
          );
          setHidePhotosensitive(true);
        }}
      >
        <HiX className="h-4 w-4 text-gray-800" />
      </Button>
    </Card>
  ) : null;
}
export default Warnings;
