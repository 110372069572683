import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Fragment } from 'react';

import Button from '../Button';
import Transition from '../Transition';

export enum Languages {
  DE = 'de',
  EN = 'en',
  FR = 'fr',
  IT = 'it',
}

interface HeaderProps {
  language: Languages;
  setLanguage: (lang: Languages) => void;
}

function Header({ language, setLanguage }: HeaderProps) {
  return (
    <div className="flex-initial sticky top-0 shadow-sm bg-gray-800 z-100">
      <div className="flex justify-between items-center py-2 px-6 text-white">
        <div className="text-lg">Flash MyLight</div>
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <MenuButton className="flex items-center justify-center p-1 bg-gray-700 hover:bg-gray-600 rounded-lg w-12 uppercase">
                {language}
              </MenuButton>

              <Transition show={open} as={Fragment}>
                <MenuItems className="absolute right-0 mt-2 w-12 origin-top-right bg-gray-700 divide-y divide-gray-400 rounded-md shadow-lg text-sm flex flex-col">
                  {Object.values(Languages).map((l) => (
                    <MenuItem key={l}>
                      <Button
                        id={l}
                        className={`uppercase p-2 ${
                          l === language ? 'font-bold bg-gray-500' : ''
                        }`}
                        onClick={() => setLanguage(l)}
                      >
                        {l}
                      </Button>
                    </MenuItem>
                  ))}
                </MenuItems>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
}

export default Header;
