import { FaRegPauseCircle, FaRegPlayCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';

interface PlayButtonProps {
  active: boolean;
  setActive: (active: boolean) => void;
  disabled: boolean;
}

function PlayButton({ active, setActive, disabled }: PlayButtonProps) {
  return (
    <div className="flex justify-center items-center w-full pb-2 gap-2 ">
      <Button
        id="play-button"
        dataTestId="play-button"
        className="flex justify-center items-center gap-2 p-4 shadow-lg rounded-xl ring-1 ring-black/5 shadow-black/10"
        onClick={() => setActive(!active)}
        disabled={disabled}
      >
        {active ? (
          <FaRegPauseCircle className="h-8 w-8 text-neutral-700" />
        ) : (
          <FaRegPlayCircle className="h-8 w-8 text-neutral-700" />
        )}
        <span className="font-bold text-neutral-700">
          <FormattedMessage id="Transmit settings" />
        </span>
      </Button>
    </div>
  );
}

export default PlayButton;
