import { useEffect, useMemo, useState } from "react";
import { IntlProvider } from "react-intl";
import DebugInformation from "./components/DebugInformation";
import {
	DeviceTypes,
	LS_DEVICE_TYPE,
} from "./components/DeviceTypeSelection/DeviceTypeSelection";
import Flash from "./components/Flash";
import Footer from "./components/Footer";
import Header, { Languages } from "./components/Header";
import InfoWrapper from "./components/InfoWrapper";
import PlayButton from "./components/PlayButton";
import SwitchesWrapper from "./components/SwitchesWrapper";

import Warnings from "./components/Warnings";

import translations from "./translations";
import getManchesterCoding from "./utils/getManchesterCoding";

import "./App.css";

const getDefaultDeviceType = () => {
	return ([DeviceTypes.LIGHTPAD, DeviceTypes.TWEAK_NEO] as string[]).includes(
		localStorage.getItem(LS_DEVICE_TYPE) || "",
	)
		? (localStorage.getItem(LS_DEVICE_TYPE) as DeviceTypes)
		: DeviceTypes.LIGHTPAD;
};
function App() {
	const { VITE_DEBUG_MODE } = import.meta.env;
	const [language, setLanguage] = useState<Languages>(Languages.DE);
	const [startValue] = useState("111");
	const [isSignalActive, setIsSignalActive] = useState(false);
	const [fullScreen, setFullScreen] = useState(false);
	const [timeUnit, setTimeUnit] = useState(50);
	const [deviceType, setDeviceType] = useState<DeviceTypes>(
		getDefaultDeviceType(),
	);
	const [middleValue, setMiddleValue] = useState(["0000000000"]);

	const manchesterCoded = useMemo(
		() => getManchesterCoding(startValue, middleValue),
		[startValue, middleValue],
	);

	const isLightpad = deviceType === DeviceTypes.LIGHTPAD;
	const isDisabled =
		middleValue.length === 1 && middleValue[0] === "0000000000";

	useEffect(() => {
		if (isLightpad) {
			setTimeUnit(50);
		} else {
			setTimeUnit(150);
		}
	}, [isLightpad]);

	return (
		<IntlProvider locale={language} messages={translations[language]}>
			<div className="flex flex-col min-h-screen bg-gray-50 h-full text-gray-800">
				<Header language={language} setLanguage={setLanguage} />
				<div
					className={`flex grow flex-col mt-2 ${
						fullScreen ? "overflow-y-hidden" : ""
					}`}
				>
					<InfoWrapper deviceType={deviceType} />
					{VITE_DEBUG_MODE === "true" && (
						<DebugInformation
							timeUnit={timeUnit}
							setTimeUnit={setTimeUnit}
							flashValue={`${startValue}${middleValue.join("")}`}
							manchesterCoded={manchesterCoded}
						/>
					)}
					<SwitchesWrapper
						setMiddleValue={setMiddleValue}
						deviceType={deviceType}
						setDeviceType={setDeviceType}
					/>
					<Warnings />
					<PlayButton
						active={isSignalActive}
						setActive={(val) => {
							setIsSignalActive(val);
							if (val) {
								setFullScreen(true);
							}
						}}
						disabled={isDisabled}
					/>
					<Flash
						active={isSignalActive}
						setActive={setIsSignalActive}
						timeUnit={timeUnit}
						frames={[
							...Array.from(getManchesterCoding(startValue, middleValue)),
						]}
						fullScreen={fullScreen}
						setFullScreen={setFullScreen}
					/>
					<Footer language={language} />
				</div>
			</div>
		</IntlProvider>
	);
}

export default App;
